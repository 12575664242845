import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";
import { transformEmptyToUndefined } from "helpers";

export const validationSchema = Yup.object().shape({
  idEnterpriseStatus: Yup.number().required(<Trans i18nKey="required.emitterStatus" />),
  enterpriseSubStatusId: Yup.number().optional().nullable(),
  name: Yup.string()
    .max(50, <Trans i18nKey="short.name" />)
    .required(<Trans i18nKey="required.name" />),
  email: Yup.string()
    .email(<Trans i18nKey="invalid.email" />)
    .required(<Trans i18nKey="required.email" />),
  billingEmail: Yup.string().email(<Trans i18nKey="invalid.billingEmail" />),
  phoneNumber: Yup.string()
    .min(14, <Trans i18nKey="short.phone" />)
    .required(<Trans i18nKey="required.phone" />),
  billingPhone: Yup.string().min(14, <Trans i18nKey="short.phone" />),
  cpf: Yup.string()
    .min(11, <Trans i18nKey="short.cpf" />)
    .required(<Trans i18nKey="required.cpf" />),
  dateOfBirth: Yup.string().nullable().optional(),
  cnpj: Yup.string().when(["idEnterpriseStatus"], (idEnterpriseStatus) => {
    if (idEnterpriseStatus === 4) {
      return Yup.string().required(<Trans i18nKey="required.cnpj" />);
    }
  }),
  //cnpj:  Yup.string().required(<Trans i18nKey="required.cnpj" />),
  enterpriseName: Yup.string().when(["idEnterpriseStatus"], (idEnterpriseStatus) => {
    if (idEnterpriseStatus === 4) {
      return Yup.string().required(<Trans i18nKey="required.enterpriseName" />);
    }
  }),
  fantasyName: Yup.string(),
  stateRegistration: Yup.string().max(30, <Trans i18nKey="short.stateRegistration" />),
  cityRegistration: Yup.string()
    .max(30, <Trans i18nKey="short.cityRegistration" />)
    .when(["stateRegistration", "state", "idEnterpriseStatus"], {
      is: (value, state, idEnterpriseStatus) =>
        !value && state !== "SP" && idEnterpriseStatus === 4,
      then: Yup.string().required(<Trans i18nKey="required.cityOrStateRegistration" />),
    }),
  idEnterpriseActivity: Yup.string().when(["idEnterpriseStatus"], (idEnterpriseStatus) => {
    if (idEnterpriseStatus === 4) {
      return Yup.string().required(<Trans i18nKey="required.activity" />);
    }
  }),
  secondaryActivities: Yup.string(),
  invoiceTypes: Yup.string(),
  zipCode: Yup.string()
    .min(10, <Trans i18nKey="short.zipCode" />)
    .required(<Trans i18nKey="required.zipCode" />),
  state: Yup.string().required(<Trans i18nKey="required.state" />),
  city: Yup.string()
    .max(50, <Trans i18nKey="short.city" />)
    .required(<Trans i18nKey="required.city" />),
  neighborhood: Yup.string()
    .max(50, <Trans i18nKey="short.neighborhood" />)
    .required(<Trans i18nKey="required.neighborhood" />),
  street: Yup.string()
    .max(50, <Trans i18nKey="short.street" />)
    .required(<Trans i18nKey="required.street" />),
  number: Yup.string()
    .max(20, <Trans i18nKey="short.number" />)
    .required(<Trans i18nKey="required.number" />),
  complement: Yup.string().max(50, <Trans i18nKey="short.complement" />),
  idAuthenticationType: Yup.number(),
  idSpecialTaxRegime: Yup.string()
    .transform(transformEmptyToUndefined)
    .when("specialTaxationRegime", {
      is: (value) => !!value,
      then: Yup.string().required(<Trans i18nKey="required.specialTaxRegime" />),
    }),
  culturalPromoter: Yup.bool().required(<Trans i18nKey="required.culturalPromoter" />),
  specialTaxationRegime: Yup.bool(),
  issAliquot: Yup.string().required("Necessário inserir um número"),
  isNationalMEI: Yup.boolean().nullable(),
  nationalMEIUser: Yup.string().nullable(),
  // .when("isNationalMEI", {
  //   is: (value) => !!value,
  //   then: Yup.string().when("idAuthenticationType", {
  //     is: 2,
  //     then: Yup.string().required(<Trans i18nKey="required.nationalMEIUser" />),
  //     otherwise: Yup.string().nullable(),
  //   }),
  // }),
  nationalMEIPassword: Yup.string().nullable(),
  constitution: Yup.string().nullable().optional(),
  // .required("O campo constituição é obrigátorio"),
  priority: Yup.string().nullable().optional(),
  responsibleSale: Yup.string().nullable().optional(),
  // .typeError("É necessario escolher um responsável")
  // .required("É necessario escolher um responsável"),
  source: Yup.string().nullable().optional(),
  financialStatus: Yup.string().nullable().optional(),
  // .typeError("É necessario escolher uma fonte")
  // .required("É necessario escolher uma fonte"),
  // .when("isNationalMEI", {
  //   is: (value) => !!value,
  //   then: Yup.string().when("idAuthenticationType", {
  //     is: 2,
  //     then: Yup.string().required(<Trans i18nKey="required.nationalMEIPassword" />),
  //     otherwise: Yup.string().nullable(),
  //   }),
  // }),
});
