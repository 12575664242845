/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import { formatNumberToReal } from "helpers";
import {
  Container,
  Image,
  ContainerMain,
  ContainerTable,
  ContainerData,
  ContainerFooter,
  ContainerHeader,
} from "./styled";
import logo from "../../../assets/images/new-logo.png";

const PageReportBilling = () => {
  const location = useLocation();
  const contentRef = useRef(null);
  const today = new Date();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState(JSON.parse(searchParams.get("dataReport")));
  // const [user, setUser] = useState(JSON.parse(searchParams.get("userDetails")));

  const handleGeneratePDF = () => {
    const element = contentRef.current;
    const opt = {
      margin: [14, 5, 10, 5],
      filename: `Faturamento${data.enterpriseName}____${data.dates.dateBegin}-${data.dates.dateEnd}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf(element, opt);
  };

  useEffect(() => {
    handleGeneratePDF();
  }, []);

  return (
    <Container ref={contentRef}>
      <ContainerMain>
        <ContainerHeader>
          <ContainerData>
            <p>{data.enterpriseName}</p>
            <p>
              Endereço:&nbsp;&nbsp;{data.street}, {data.number}
            </p>
            <p>Município:&nbsp;&nbsp;{data.city}</p>
            <p>CNPJ:&nbsp;{data.cnpj}</p>
            <p>
              Relatório de Faturamento de: &nbsp; {data.dates.dateBegin} &nbsp;até&nbsp;{" "}
              {data.dates.dateEnd}
            </p>
          </ContainerData>
          <Image src={logo} />
        </ContainerHeader>
        <ContainerTable>
          <table
            id="tabelaReceita"
            style={{ width: "40%", fontSize: "13px", paddingRight: "40px" }}
          >
            <thead>
              <tr>
                <th>Mês / Ano</th>
                <th style={{ textAlign: "right" }}>Receita Bruta</th>
              </tr>
            </thead>
            <tbody>
              {data.reportBilling.map((item) => (
                <tr key={item.sum}>
                  <td>{`${item.month}/${item.year}`}</td>
                  <td style={{ textAlign: "right" }}>{formatNumberToReal(item.sum)}</td>
                </tr>
              ))}
            </tbody>
            <hr />
            <tfoot>
              <tr>
                <td>
                  <strong>Total Geral</strong>
                </td>
                <td className="tdFooter">{formatNumberToReal(data.total)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Média</strong>
                </td>
                <td className="tdFooter">{formatNumberToReal(data.media)}</td>
              </tr>
            </tfoot>
          </table>
          <p>Contagem, &nbsp; &nbsp;{today.toLocaleDateString()}</p>
        </ContainerTable>
      </ContainerMain>
      <ContainerFooter>
        <p>
          *Este relatório foi gerado automaticamente pelo sistema da OBVIA,baseado nas notas fiscais
          emitidas em nosso aplicativo. Caso tenha notas fiscais emitidas em outros portais ou
          sistemas, não completa estas informações sobre faturamento neste relatório.
        </p>
        {/* <p>CONTADOR:&nbsp;{user.name.toUpperCase()}</p>
        <p>CRC -&nbsp;{user.crc ? user.crc.toUpperCase() : ""}</p> */}
      </ContainerFooter>
    </Container>
  );
};

export default PageReportBilling;
